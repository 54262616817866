import AppModal from "@components/AppModal";
import Locale from "@components/core/Locale";
import axios from "@config/axios";
import ENDPOINTS from "@config/endpoints";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useQuery } from "react-query";

const PlaceItem = ({ name, address, onClick }) => {
  return (
    <div onClick={onClick} className="flex flex-col border p-2 cursor-pointer hover:bg-gray-50">
      <span className=" ">{name}</span>
      <span className="text-gray-500 ">{address}</span>
    </div>
  );
};

export default function PlacePickerModal({ onClose, onChange }) {
  const [search, setSearch] = useState(undefined);
  const intl = useIntl();

  // Get Leads Mutation
  const GPM = useQuery(["places", search], async () => axios.get(ENDPOINTS.booking.place, { params: { search } }), {
    keepPreviousData: true,
  });

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(e.target?.search?.value);
  };

  return (
    <AppModal
      loading={GPM.isFetching}
      header={<Locale code="T.SELECT_LOCATION" />}
      show={true}
      onHide={onClose}
      bodyClassName="p-0"
      scrollable
      body={
        <div className="flex flex-col">
          <div className="bg-gray-50 border-b p-2">
            <form onSubmit={handleSearch}>
              <input type="text" autoFocus autoComplete="none" name="search" placeholder="Search & Enter" className="form" />
            </form>
          </div>
          <div className="flex flex-col p-2 overflow-y-auto space-y-2" style={{ maxHeight: "50vh" }}>
            {GPM.data?.map(({ name, address, location }, _index) => (
              <PlaceItem
                onClick={() => {
                  onClose();
                  onChange({ address, coordinates: location?.coordinates });
                }}
                name={name}
                address={address}
                key={_index}
              />
            ))}
          </div>
        </div>
      }
    />
  );
}
