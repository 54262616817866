import { Fragment, useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "src/context/AppContext";
import { AppLayoutContext } from "src/context/AppLayoutContext";
import { NotificationContext } from "src/context/NotificationContext";
import MobileTabBarItem from "./components/BottomTabItem";

export default function BottomTabs() {
  const { notification, call } = useContext(AppLayoutContext);
  const {
    CM: { isServiceEnabled },
  } = useContext(AppContext);

  // Callbacks
  const _handleOpenCallCenter = useCallback(() => call.show(), []);
  const _handleOpenNotifications = useCallback(() => notification.show(), []);

  const { notifications } = useContext(NotificationContext);

  // Menu
  const _MENU = [
    { title: "T.DASHBOARD", path: "/dashboard", icon: "fa-dashboard" },
    { title: "T.LEADS", path: "/leads", icon: "fa-users" },
    { title: "T.MESSENGER", path: "/messenger", icon: "fa-sms", notifications: notifications?.some(({ seen }) => !seen) },
    { title: "T.CALLCENTER", service: "call", icon: "fa-phone", onClick: _handleOpenCallCenter },
    { title: "T.NOTIFICATIONS", icon: "fa-bell", onClick: _handleOpenNotifications },
  ].filter(({ service }) => (!service ? true : isServiceEnabled(service)));

  return (
    <div className={`md:hidden flex-shrink-0 grid grid-cols-5 w-full shadow-inner fixed  bottom-0 bg-white border-t h-16 z-50 mt-10`}>
      {_MENU.map(({ icon, active, path, notifications, onClick }, _index) => (
        <Fragment key={_index}>
          {path ? (
            <Link to={path}>
              <MobileTabBarItem key={_index} icon={icon} active={active} notifications={notifications} />
            </Link>
          ) : (
            <MobileTabBarItem key={_index} icon={icon} active={active} notifications={notifications} onClick={onClick} />
          )}
        </Fragment>
      ))}
    </div>
  );
}
