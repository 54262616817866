import axios from "@config/axios";
import ENDPOINTS from "@config/endpoints";
import { AuthContext } from "@context/AuthContext";
import { useContext, useEffect, useMemo } from "react";
import { useMutation } from "react-query";

export default function useGlobal() {
  /** */

  const { isLogedIn } = useContext(AuthContext);

  // Mutations
  const countries = useMutation(() => axios.get(`${ENDPOINTS.misc}/countries`));
  const languages = useMutation(() => axios.get(`${ENDPOINTS.misc}/languages`));
  const timezones = useMutation(() => axios.get(`${ENDPOINTS.misc}/timezones`));
  const sources = useMutation(() => axios.get(ENDPOINTS.source));
  const labels = useMutation(() => axios.get(ENDPOINTS.label));
  const actions = useMutation(() => axios.get(ENDPOINTS.action));
  const levels = useMutation(() => axios.get(ENDPOINTS.level));
  const notificationCodes = useMutation(() => axios.get(`${ENDPOINTS.notification}/codes`));
  const rates = useMutation(() => axios.get(ENDPOINTS.rate));
  const clinics = useMutation(() => axios.get(ENDPOINTS.clinic));
  const treatments = useMutation(() => axios.get(ENDPOINTS.treatment));
  const personnel = useMutation(() => axios.get(ENDPOINTS.staff));
  const users = useMutation(() => axios.get(ENDPOINTS.user, { params: { status: "all" } }));
  const groups = useMutation(() => axios.get(ENDPOINTS.group));
  const categories = useMutation(() => axios.get(ENDPOINTS.category));
  const fields = useMutation(() => axios.get(ENDPOINTS.field));
  const mediaTags = useMutation(() => axios.get(ENDPOINTS.mediaTag));
  const taxGroups = useMutation(() => axios.get(ENDPOINTS.tax));
  const tags = useMutation(() => axios.get(ENDPOINTS.tag));

  // Memoized Mutations
  const allMutations = useMemo(
    () => [
      countries,
      languages,
      timezones,
      sources,
      labels,
      actions,
      levels,
      notificationCodes,
      rates,
      clinics,
      treatments,
      personnel,
      users,
      groups,
      categories,
      fields,
      mediaTags,
      taxGroups,
      tags,
    ],
    []
  );

  useEffect(() => {
    if (isLogedIn) allMutations.forEach((mutation) => mutation.mutate()); // Mutate All
  }, [isLogedIn, allMutations]);

  // Memoized Countries Map
  const countriesMap = useMemo(() => {
    return (
      countries.data?.reduce((acc, { code, name }) => {
        acc[code] = name;
        return acc;
      }, {}) ?? {}
    );
  }, [countries.data]);

  // Memoized Countries For Forms Consumtion ({label: name, value: code})
  const countriesForm = useMemo(() => {
    return countries.data?.map(({ code, name }) => ({ label: name, value: code }));
  }, [countries.data]);

  // Memoized Languages For Forms Consumtion ({label: name, value: code})
  const languagesForm = useMemo(() => {
    return languages.data?.map(({ code, name }) => ({ label: name, value: code }));
  }, [languages.data]);

  // Memoized Processed Users
  const processedUsers = useMemo(() => {
    const allUsers = users?.data ?? [];
    return {
      all: allUsers,
      active: allUsers.filter(({ active }) => active),
      deactive: allUsers.filter(({ active }) => !active),
    };
  }, [users.data]);

  // Memoized Is Loading
  const isLoading = useMemo(() => allMutations.some((mutation) => mutation.isLoading), [allMutations]);

  return useMemo(
    () => ({
      countries: countries.data ?? [], // All Countries (Array)
      countries_map: countriesMap, // Countries Map (Object)
      countries_form: countriesForm, // Countries Form ({label: name, value: code})
      languages: languages.data ?? [], // All Languages (Array)
      languages_form: languagesForm, // Languages Form ({label: name, value: code})
      timezones: timezones.data ?? [],
      sources: sources.data ?? [],
      channels: sources.data?.flatMap(({ channels }) => channels) ?? [], // All Channels (Array)
      labels: labels.data ?? [],
      actions: actions.data ?? [],
      levels: levels.data ?? [],
      notificationCodes: notificationCodes.data ?? [],
      rates: rates.data ?? [],
      clinics: clinics.data ?? [],
      treatments: treatments.data ?? [],
      personnel: personnel.data ?? [],
      users: processedUsers,
      groups: groups.data ?? [],
      categories: categories.data ?? [],
      fields: fields.data ?? [],
      mediaTags: mediaTags.data ?? [],
      taxGroups: taxGroups.data ?? [],
      tags: tags.data ?? [],
      isLoading,
    }),
    [
      countries.data,
      countriesMap,
      languages.data,
      timezones.data,
      sources.data,
      labels.data,
      actions.data,
      levels.data,
      notificationCodes.data,
      rates.data,
      clinics.data,
      treatments.data,
      personnel.data,
      processedUsers,
      groups.data,
      categories.data,
      fields.data,
      mediaTags.data,
      taxGroups.data,
      tags.data,
      isLoading,
    ]
  );
}
