import { APP_VERSION } from "@config";
import axios from "@config/axios";
import { useContext, useEffect } from "react";
import { useMutation } from "react-query";
import ENDPOINTS from "src/config/endpoints";
import { AuthContext } from "src/context/AuthContext";
import VersionAlertModal from "./components/VersionAlertModal";

export default function AppIntervalChecks() {
  /** */

  const { token, logout } = useContext(AuthContext);

  // Get App Latest Version
  const GVM = useMutation(() => axios.get(`${ENDPOINTS.misc}/app/version/latest`));

  // Verify User Token
  const VUM = useMutation((token) => axios.post(`${ENDPOINTS.app.auth}/verify`, { token }), {
    onSuccess: (response) => {
      if (!response) logout();
    },
  });

  // Check User Token
  const checkUserToken = () => {
    if (token) VUM.mutate(token);
  };

  useEffect(() => {
    GVM.mutate(); // Initial Check
    const interval1 = setInterval(GVM.mutate, 60 * 60 * 1000); // Re-check Every 60 Minutes
    const interval2 = setInterval(checkUserToken, 60 * 1000); // Re-check Every 1 Minute
    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
    };
  }, []);

  return <>{GVM.data?.number > Number(APP_VERSION ?? 0) && <VersionAlertModal version={GVM.data} />}</>;
}
