import AppButton from "@components/AppButton";
import AppIcon from "@components/AppIcon";
import Locale from "@components/core/Locale";
import axios from "@config/axios";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { TIMEZONE_REMINDER_DELAY, TIMEZONE_REMINDER_KEY } from "src/config";
import ENDPOINTS from "src/config/endpoints";
import { AppContext } from "src/context/AppContext";
import { AuthContext } from "src/context/AuthContext";
import toast from "src/helpers/toast";

const TimezoneCheck = () => {
  /** */

  const { CM, PM } = useContext(AppContext);
  const { makeCDNURL } = useContext(AuthContext);

  const [alert, setAlert] = useState(false);
  const [detectedTimezone, setDetectedTimezone] = useState("");

  // Update Profile Timezone
  const SDM = useMutation((values) => axios.patch(`${ENDPOINTS.user}/me/timezone`, values), {
    onSuccess: () => {
      toast.success("Timezone Updated");
      window.location.reload(); // Reload Page
    },
  });

  // Timezones Difference Check
  const checkTimezone = () => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // User Timezone
    setDetectedTimezone(userTimezone);
    if (userTimezone !== PM.profile?.default?.timezone) {
      const lastReminderTime = localStorage.getItem(TIMEZONE_REMINDER_KEY); // Last Reminder Time
      const currentTime = Date.now(); // Current Time
      setAlert(!lastReminderTime || currentTime - parseInt(lastReminderTime) >= TIMEZONE_REMINDER_DELAY); // No Previous Reminder or 24 Hours Passed
    }
  };

  // Handle Update Timezone
  const handleUpdate = () => SDM.mutate({ timezone: detectedTimezone });

  const handleClose = () => {
    setAlert(false); // Close Alert
    localStorage.setItem(TIMEZONE_REMINDER_KEY, Date.now().toString()); // Set Reminder Time
  };

  useEffect(() => {
    checkTimezone();
  }, []);

  if (!alert) return null;

  return (
    <div className="fixed inset-0 bg-white z-50 flex items-center justify-center p-4">
      <div className="max-w-2xl w-full relative">
        <div className="flex flex-col items-center text-center space-y-6">
          {/* Logo */}
          <img src={makeCDNURL(CM?.profile?.logo)} alt="Logo" className="w-32 h-12 object-contain" />
          {/* Content */}
          <div className="space-y-4">
            <h1 className="text-2xl font-semibold text-gray-900">
              <Locale code="T.TIMEZONE_CHECK_TITLE" />
            </h1>
            <p className="text-gray-600 max-w-lg">
              <Locale code="T.TIMEZONE_CHECK_DESCRIPTION" />
            </p>
          </div>

          {/* Timezone Info Box */}
          <div className="w-full border border-gray-200 rounded-sm p-4 space-y-4">
            <div className="flex flex-col space-y-2">
              <div className="flex items-center justify-between p-4 bg-gray-50 rounded-sm">
                <div className="flex items-center gap-2">
                  <AppIcon code="fa-globe" className="w-5 h-5 text-gray-500" />
                  <span className="text-gray-500 font-medium">
                    <Locale code="T.DETECTED_TIMEZONE" />
                  </span>
                </div>
                <span className="text-gray-700 font-medium">{detectedTimezone}</span>
              </div>
              <div className="flex items-center justify-between p-4 bg-gray-50 rounded-sm">
                <div className="flex items-center gap-2">
                  <AppIcon code="fa-clock" className="w-5 h-5 text-gray-500" />
                  <span className="text-gray-500 font-medium">
                    <Locale code="T.PROFILE_TIMEZONE" />
                  </span>
                </div>
                <span className="text-gray-700 font-medium">{PM.profile?.default?.timezone || "Not set"}</span>
              </div>
            </div>
          </div>

          {/* Note */}
          <p className="text-sm text-gray-500 max-w-lg">
            <Locale code="T.TIMEZONE_CHECK_NOTE" />
          </p>

          {/* Actions */}
          <div className="flex gap-2">
            <AppButton mode="primary" onClick={handleUpdate} title="BTN.UPDATE" loading={SDM.isLoading} />
            <AppButton mode="light" onClick={handleClose} title="BTN.REMIND_LATER" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimezoneCheck;
