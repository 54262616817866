import AppButton from "@components/AppButton";
import AppIcon from "@components/AppIcon";
import axios from "@config/axios";
import ENDPOINTS from "@config/endpoints";
import toast from "@helpers/toast";
import { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import toggleInclusion from "../../../utilities/toggleInclusion";
import AppImage from "../../AppImage";
import AppModal from "../../AppModal";
import ProtectedMedia from "../../ProtectedMedia";

export default function MediaPickerModal({ show, onClose, leadID, multiple = false, onSelect, defaultSelected = [] }) {
  const [selected, setSelected] = useState([]);
  const uploadRef = useRef(null);

  // Get Medias Mutation
  const GMM = useMutation(() => axios.get(leadID ? `${ENDPOINTS.lead}/${leadID}/medias` : ENDPOINTS.media));

  // Upload Media Mutation
  const UMM = useMutation(
    (formData) =>
      axios.post(leadID ? `${ENDPOINTS.lead}/${leadID}/medias` : ENDPOINTS.media, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
    {
      onSuccess: () => GMM.mutate(),
      onError: () => toast.warning("Failed to upload thumbnail"),
    }
  );

  // Handle Upload File
  const handleUploadMedia = (e) => {
    const formData = new FormData();
    for (let index = 0; index < e.target.files.length; index++) {
      formData.append("media", e.target.files[index]);
    }
    UMM.mutate(formData);
    uploadRef.current.value = null;
  };

  useEffect(() => {
    GMM.mutate();
    setSelected(defaultSelected);
  }, []);

  return (
    <>
      <input type="file" disabled={UMM.isLoading} hidden ref={uploadRef} onChange={handleUploadMedia} />

      <AppModal
        size="lg"
        scrollable
        show={show}
        onHide={onClose}
        header="T.SELECT_FILE"
        loading={GMM.isLoading}
        body={
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
            {GMM.data?.map((media, _index) => {
              return (
                <div
                  className="col flex flex-col border rounded-sm space-y-2 cursor-pointer hover:bg-gray-50"
                  onClick={() => setSelected((x) => (multiple ? toggleInclusion(x, media._id) : [media._id]))}
                  key={_index}
                >
                  <div className="flex items-center justify-between px-4 py-2 border-b bg-gray-50">
                    <span className="capitalize truncate">{media?.name}</span>
                    <AppIcon code="fa-circle" className={`text-2xl mx-2 ${selected?.includes(media._id) ? `text-blue-500` : `text-gray-300`}`} />
                  </div>
                  <div className="p-2">
                    {["image", "video", "audio"].includes(media.mime?.split("/")?.[0]) ? (
                      <ProtectedMedia mime={media.mime} mediaID={media._id} className="w-full h-48 object-contain rounded-sm" />
                    ) : (
                      <AppImage code={media.mime} />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        }
        footer={
          <div className="flex justify-between items-center w-full">
            <AppButton icon="fa-upload" title="BTN.UPLOAD" loading={UMM.isLoading} onClick={() => uploadRef.current?.click()} />
            <AppButton title="BTN.SELECT" mode="primary" disabled={selected.length === 0} onClick={() => onSelect(selected)} />
          </div>
        }
      />
    </>
  );
}
