import axios from "@config/axios";
import ENDPOINTS from "@config/endpoints";
import { AppContext } from "@context/AppContext";
import { Device } from "@twilio/voice-sdk";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";

export default function useTwilioCall(_callEnabled) {
  const {
    PM: { profile },
  } = useContext(AppContext);

  const [call, setCall] = useState(undefined);
  const [callerID, setCallerID] = useState(profile.default?.callerID);
  const [device, setDevice] = useState(undefined);
  const [accessToken, setAccessToken] = useState(undefined);

  // Get Access Token Mutation
  const GetAccessToken = useMutation(() => axios.get(`${ENDPOINTS.integration.twilio}/sdk/token`), {
    onSuccess: (response) => {
      if (response?.token) setAccessToken(response?.token);
    },
  });

  const _listeners = {
    call: {
      accept: () => setCall((x) => ({ ...x, status: "accept" })),
      reject: () => setCall((x) => ({ ...x, status: "closed" })),
      disconnect: () => setCall((x) => ({ ...x, status: "closed" })),
      cancel: () => setCall((x) => ({ ...x, status: "closed" })),
    },
  };

  const _removePreviousCallListeners = () => {
    call.instance?.off("accept", _listeners.call.accept);
    call.instance?.off("reject", _listeners.call.reject);
    call.instance?.off("disconnect", _listeners.call.disconnect);
    call.instance?.off("cancel", _listeners.call.cancel);
    return;
  };

  // Initiate a New Device
  const _initiateDevice = (accessToken) => {
    if (!accessToken) return;
    const newDevice = new Device(accessToken);
    setDevice(() => ({ instance: newDevice, status: "pending" }));
    newDevice.on("registered", () => setDevice((x) => ({ ...x, status: "registered" })));
    newDevice.on("unregistered", () => setDevice((x) => ({ ...x, status: "unregistered" })));
    newDevice.on("registering", () => setDevice((x) => ({ ...x, status: "registering" })));
    newDevice.on("tokenWillExpire", () => GetAccessToken.mutate());
    newDevice.on("incoming", (call) => _initiateCall(call, "inbound"));
    newDevice.on("error", (error) => {
      console.log("Error registering the device", error);
      // GetAccessToken.mutate();
    });
    return newDevice.register();
  };

  // Update Device Token
  const _updateDevice = (accessToken) => {
    device?.instance?.updateToken(accessToken);
    return;
  };

  // Initiate a Call (Inbound/Outbound)
  const _initiateCall = (newCall, direction, to = undefined) => {
    if (call) _removePreviousCallListeners();
    newCall.on("accept", _listeners.call.accept);
    newCall.on("reject", _listeners.call.reject);
    newCall.on("disconnect", _listeners.call.disconnect);
    newCall.on("cancel", _listeners.call.cancel);
    setCall({ instance: newCall, direction, status: newCall.status(), to });
    return;
  };

  // AccessToken Callback
  useEffect(() => {
    if (!accessToken) return;
    if (!device) _initiateDevice(accessToken); // Create New Device
    else _updateDevice(accessToken); // Update Device Access Token
  }, [accessToken]);

  // First Run
  useEffect(() => {
    if (!_callEnabled) return;
    if (!device) GetAccessToken.mutate();
    return () => {
      if (device) {
        device.instance?.removeAllListeners();
        device.insatnce?.destroy();
      }
    };
  }, []);

  // :: Methods :: Make an Outbound Call
  const _makeCall = async (callID, to) => {
    if (!callID) return;
    try {
      const newCall = await device.instance?.connect({
        params: {
          callID,
        },
      });
      _initiateCall(newCall, "outbound", to);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    device: { instance: device?.instance, status: device?.status, callerID },
    call: {
      instance: call?.instance,
      direction: call?.direction,
      status: call?.status,
      active: ["connecting", "open", "pending", "reconnecting", "ringing", "accept"].includes(call?.status),
      callID: call?.instance?.customParameters?.get("callID"),
      displayedNumber: call?.direction === "inbound" ? call?.instance?.parameters?.From : call?.to,
    },
    methods: {
      updateCallerID: (callerID) => (callerID ? setCallerID(callerID) : {}),
      makeCall: (callID, to) => _makeCall(callID, to),
    },
  };
}
