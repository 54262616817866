import AppButton from "@components/AppButton";
import Locale from "@components/core/Locale";
import axios from "@config/axios";
import ENDPOINTS from "@config/endpoints";
import { AuthContext } from "@context/AuthContext";
import { Field, Formik } from "formik";
import queryString from "query-string";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { AppInput, RowInput } from "../../components/form";

export default function LoginPage() {
  const [error, setError] = useState(null);
  const { login } = useContext(AuthContext);

  const URLParams = queryString.parse(useLocation().search);
  const navigate = useNavigate();

  // Referencial Token (Token Already Obtained By Universal Login Mechanism)
  useEffect(() => {
    if (URLParams.token) login(URLParams?.token);
  }, [URLParams.token]);

  // Get Public Assets
  const GPM = useMutation(() => axios.get(`${ENDPOINTS.app.public}/${window.location.href.split("/")[2]}`));

  useEffect(() => {
    GPM.mutate(); // Public Assets
  }, []);

  // Login Mutation
  const LDM = useMutation((values) => axios.post(`${ENDPOINTS.app.auth}/login`, values), {
    onSuccess: (response) => {
      const token = response?.token;
      const TFA = response?.TFA ?? {}; // Two Factor Authentication Details
      if (TFA?.required) navigate(`/auth/2fa?${queryString.stringify({ token, ...TFA })}`); // TFA Required
      else login(token); // TFA Not Required
    },
    onError: (error) => {
      console.log(error);
      setError(error);
    },
  });

  return (
    <div className="flex h-screen">
      {/* Left side - Image */}
      <div className="hidden lg:block lg:w-1/2 relative">
        {/* <img src={`${ENDPOINTS.cdn}/public/medias/${GPM.data?.banner || "default-banner.jpg"}`} className="h-full w-full object-cover" alt="Login banner" /> */}
        <div className="absolute inset-0 bg-black/40" />
      </div>

      {/* Right side - Form */}
      <div className="w-full lg:w-1/2 flex items-center justify-center px-6 lg:px-12">
        <div className="w-full max-w-md">
          <div className="flex flex-col items-center mb-8">
            <img className="h-12 w-auto mb-6 object-contain" src={`${ENDPOINTS.cdn}/public/medias/${GPM.data?.logo}`} alt="Company logo" />
            <div className="flex items-center gap-2 mb-2">
              <h1 className="text-2xl font-semibold text-gray-900">
                <Locale code="T.LOGIN_TITLE" />
              </h1>
            </div>
            <p className="text-gray-500 text-center">
              <Locale code="T.LOGIN_DESCRIPTION" />
            </p>
          </div>

          <Formik initialValues={{ email: "", password: "" }} onSubmit={LDM.mutate}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate className="space-y-4">
                <Field required type="email" name="email" component={RowInput} FormElement={AppInput} placeholder="L.EMAIL" />
                <Field component={RowInput} FormElement={AppInput} required name="password" type="password" placeholder="L.PASSWORD" />
                <AppButton mode="primary" title="BTN.LOGIN" loading={LDM.isLoading} className="w-full mt-6" />
                {error && <div className="text-red-500 text-sm text-center mt-2">{error.message}</div>}
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
