import AppIcon from "@components/AppIcon";
import AppImage from "@components/AppImage";
import clx from "classnames";
import { memo } from "react";
const MiniSidebarItem = ({ icon, active, notification, onClick, media }) => {
  return (
    <div
      onClick={onClick}
      className={clx("flex h-16 w-full items-center justify-center cursor-pointer relative", {
        "bg-gray-100 ": active,
        "bg-white hover:bg-gray-100 text-gray-600 ": !active,
      })}
    >
      {media ? <AppImage code={media} className="w-12 h-10 object-contain" /> : <AppIcon code={icon} className="w-6" aria-hidden="true" />}
      {notification && <span className="w-4 h-4 text-blue-500 absolute top-2 right-1">●</span>}
    </div>
  );
};
export default memo(MiniSidebarItem);
