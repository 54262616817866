import axios from "@config/axios";
import ENDPOINTS from "@config/endpoints";
import AuthorizeLoading from "@modules/auth/components/AuthorizeLoading";
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMutation } from "react-query";

const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  /** */

  // Token
  const [token, setToken] = useState(null);

  // User
  const [user, setUser] = useState(undefined);

  // Loading
  const [loading, setLoading] = useState(true);

  // Interceptor ID reference
  const interceptorRef = useRef(null);

  // Authorize Axios
  const authorize = useCallback((token) => {
    /** */

    // Remove Existing Interceptor
    if (interceptorRef.current !== null) {
      axios.interceptors.request.eject(interceptorRef.current);
    }

    // Add New Interceptor
    interceptorRef.current = axios.interceptors.request.use(
      (config) => {
        config.headers["authorization"] = `Bearer ${token}`;
        return config;
      },
      (error) => Promise.reject(error)
    );
  }, []);

  // De-Authorize Axios
  const deAuthorize = useCallback(() => {
    if (interceptorRef.current !== null) {
      axios.interceptors.request.eject(interceptorRef.current); // Remove Existing Interceptor
      interceptorRef.current = null;
    }
  }, []);

  // Login
  const login = useCallback((newToken) => {
    setToken(newToken);
  }, []);

  // Logout
  const logout = useCallback(() => {
    setToken(null); // Remove Token
    setUser(undefined); // Remove User
    localStorage.removeItem("token"); // Remove Token From Local Storage
    deAuthorize(); // De-Authorize Axios
    setLoading(false);
  }, [deAuthorize]);

  // Check Permission Code
  const isAccessible = useCallback((code) => (!code || user?.type === "company" ? true : Boolean(user?.permissions?.includes(code))), [user]);

  // Check User Type
  const isAdmin = useCallback(() => user?.type === "company", [user]);

  // Verify User Mutation
  const VUM = useMutation((token) => axios.post(`${ENDPOINTS.app.auth}/verify`, { token }), {
    onSuccess: (response) => {
      setUser(response);
      setLoading(false);
      localStorage.setItem("token", token); // Persist Token In Local Storage
      authorize(token); // Authorize Axios only after token is verified
    },
    onError: (error) => logout(),
  });

  // Initial Check
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) setToken(token);
    else setLoading(false);
  }, [authorize]);

  // Token Check
  useEffect(() => {
    if (!token) return;
    VUM.mutate(token); // Verify Token
  }, [token]);

  const makeCDNURL = useCallback((mediaID) => `${ENDPOINTS.cdn}/app/medias/${mediaID}/download?token=${token}`, [token]);

  const value = useMemo(
    () => ({
      isLogedIn: Boolean(user),
      token,
      login,
      isAccessible,
      isAdmin,
      user,
      logout,
      makeCDNURL,
    }),
    [user, token, login, isAccessible, isAdmin, logout, makeCDNURL]
  );

  return <Provider value={value}>{loading ? <AuthorizeLoading /> : children}</Provider>;
};

export { AuthContext, AuthProvider };
