import toast from "@helpers/toast";
import Axios from "axios";
import { HIDDEN_TOAST_ERROR_CODES } from ".";
const axios = Axios.create({
  headers: {
    "Content-Type": "application/json",
    "App-Locale": localStorage.getItem("locale"),
  },
});

// Response interceptor
axios.interceptors.response.use(
  (response) => {
    if (response.headers["content-type"] !== "application/json; charset=utf-8") return response;
    if (response.data.status !== 200) {
      const message = response.data.error?.message;
      if (!HIDDEN_TOAST_ERROR_CODES.includes(response.data.status)) {
        if (Array.isArray(message)) message?.map((msg) => toast.warning(msg));
        else toast.warning(message);
      }
      return Promise.reject({ message: response?.data?.error?.message, code: response?.data?.status });
    }
    return response.data.data;
  },
  (error) => {
    if (error.response && error.response.data) {
      return Promise.reject({
        message: error.response.data.error?.message || "An unexpected error occurred",
        fields: error.response.data.error?.fields || {},
      });
    }
    return Promise.reject({
      message: error.message || "Network error",
      fields: {},
    });
  }
);

export default axios;
